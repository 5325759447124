// log
import store from "../store";

const fetchDataBaseRequest = () => {
  return {
    type: "CHECK_DATABASE_REQUEST",
  };
};

const fetchDataBaseSuccess = (payload) => {
  return {
    type: "CHECK_DATABASE_SUCCESS",
    payload: payload,
  };
};

const fetchDataBaseFailed = (payload) => {
  return {
    type: "CHECK_DATABASE_FAILED",
    payload: payload,
  };
};

export const fetchDataBase = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataBaseRequest());
    try {
      let isAlEnabled0 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(0)
        .call();
      let isAlEnabled1 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(1)
        .call();
      let isAlEnabled2 = await store
        .getState()
        .blockchain.smartContract.methods.isAlSaleEnabled(2)
        .call();

      dispatch(
        fetchDataBaseSuccess({
          isAlEnabled0,
          isAlEnabled1,
          isAlEnabled2,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataBaseFailed("Could not load data from contract."));
    }
  };
};
