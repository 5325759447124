const initialState = {
  loading: false,
  isAlEnabled0: false,
  isAlEnabled1: false,
  isAlEnabled2: false,
  unload: true,
  error: false,
  errorMsg: "",
};

const dataBaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATABASE_REQUEST":
      return {
        ...state,
        loading: true,
        unload: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABASE_SUCCESS":
      return {
        ...state,
        loading: false,
        // tokenURI: action.payload.tokenURI,
        isAlEnabled0: action.payload.isAlEnabled0,
        isAlEnabled1: action.payload.isAlEnabled1,
        isAlEnabled2: action.payload.isAlEnabled2,
        // cost: action.payload.cost,
        unload: false,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABASE_FAILED":
      return {
        ...initialState,
        loading: false,
        unload: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataBaseReducer;
